.ContactBar {
  position: fixed;
  width: var(--gutter);
  height: calc(80vh - var(--nav-height));
  bottom: 0;
  right: 0;
  display: grid;
  grid-template: repeat(3, 1fr) / 100%;
  justify-items: center;
  gap: 1rem;
}

.ContactBar_linkWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.ContactBar_link {
  text-transform: uppercase;
  writing-mode: vertical-lr;
  font-size: 1rem;
}

.ContactBar_anchor {
  color: #777777 !important;
}

.ContactBar_anchor::after {
  content: none;
}

.ContactBar_line {
  height: 100%;
  width: 1px;
  background-color: #777777;
}