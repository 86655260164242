:root {
	--home-image-height: 225px;
}

.Home {
	height: 100vh;
  padding: var(--nav-height) var(--gutter);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
	position: relative;
}

.Home_bottomBox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	padding-top: 1.25rem;
	width: 100%;
  margin: auto;
}

@media screen and (max-width: 768px) {

  .Home {
    min-height: 100vh;
    height: unset;
  }

  .Home_bottomBox {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 2.5rem;
  }

}
