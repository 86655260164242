/* Navbar sections */

.Navbar {
  position: fixed;
  width: 100vw;
  height: var(--nav-height);
  padding: 0 var(--gutter);
  z-index: 3;
  background-color: var(--dark);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.Navbar_navList ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  padding-left: 0px;
	position: relative;
}


/* Navbar text effects */

.Navbar_link {
  font-size: var(--nav-text-size);
  font-family: var(--sans-serif-font);
  padding-left: 0;
  list-style: none;
	margin-left: 3vw;
	position: relative;
}

/* Add underline styling for nav item on hover */

.Navbar_link::after {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 100%;
	bottom: -0.05em;
	background: var(--light);
	height: 0.075em;
	transition: right 0.4s ease-in-out;
}

/* Color overrides for styled underlines */
.Navbar_link--red::after {
	background: var(--red);
}

.Navbar_link--blue::after {
	background: var(--blue);
}

.Navbar_link--yellow::after {
	background: var(--yellow);
}

.Navbar_link:hover::after,
.Navbar_link:focus::after,
.Navbar_link:active::after {
	right: 0;
}

.Navbar_logo {
  color: var(--light);
  font-weight: bold;
  font-family: var(--sans-serif-font);
  font-size: var(--nav-text-size);
  position: relative;
}

.Navbar_button {
  background-color: var(--dark);
  color: var(--light);
  border: none;
  border-radius: none;
  line-height: 16px;
  width: 36px;
  height: 32px;
  position: relative;
}

.Navbar_button > i {
  height: 100%;
  width: 100%;
  display: flex;
  padding: 0;
  margin: auto;
  justify-content: center;
  align-items: center;
  font-size: 1rem; 
  position: absolute;
  top: 0;
  left: 0;
}

.fa-plus {
  transform: rotateZ(0deg);
  transition: transform var(--transition);
}

.Navbar_button--rotate {
  transform: rotateZ(45deg);
  transition: transform var(--transition);
}

.Navbar_logo::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: -0.05em;
  background: linear-gradient(
    90deg, 
    var(--red) 0%, 
    var(--red) 24%, 
    var(--blue) 38%,
    var(--blue) 62%,
    var(--yellow) 76%,
    var(--yellow) 100%
  );
  height: 0.125em;
  transition: right var(--transition);
}

.Navbar_logo:hover::after,
.Navbar_logo:focus::after,
.Navbar_logo:active::after {
  right: 0;
}

/* Override default a underlining just for navbar and replace with style similar to nav links in Home.js */

.Navbar_navList > a::after {
    content: none;
}

.Navbar_link:hover {
	cursor: pointer;
}

.Navbar_dropdown {
  z-index: 3;
  width: 100%;
  opacity: 100%;
  visibility: visible;
  position: absolute;
  padding-left: var(--gutter);
  right: 0;
  top: var(--nav-height);
  box-shadow: 0px 3px 8px rgba(0,0,0,0.6), 
      inset 0px 0px 8px #111;
  transition: opacity var(--transition);
}

.Navbar_dropdown > ul {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: right;
  padding: 0;
  margin: 0;
}

.Navbar_dropdownBlur {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  overflow: hidden;
  background-color: #222;
}

.Navbar_link--dropdown {
  width: 100%;
  font-size: var(--nav-text-size);
  color: var(--light);
  background-color: var(--dark);
  list-style: none;
  display: block;
  padding-bottom: 0.6em;
  text-align: left;
  padding-right: var(--gutter);
}

.Navbar_noDisplay {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity var(--transition),
    visibility 0s 0.4s;
}
