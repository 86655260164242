:root {
	--headline-size: 5.8rem;
	--scroller-padding: 5.8rem;
  --headline-hint-dot-animation: Headline_hint--dot 2.8s ease-in-out infinite forwards;
}

.Headline {
	width: 100%;
	margin-top: 1.25rem;
}

.Headline_scrollContainer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	width: 100%;
	overflow: hidden;
}

.Headline_scrollContainer:hover > * {
	-webkit-animation: textScroller 8s linear infinite;
	        animation: textScroller 8s linear infinite;
}

.Headline_scrollWrapper {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	    -ms-flex-direction: row;
	        flex-direction: row;
	-webkit-box-pack: start;
	    -ms-flex-pack: start;
	        justify-content: flex-start;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: nowrap;
	    flex-wrap: nowrap;
	height: var(--headline-size);
	gap: var(--scroller-padding);
	padding-right: var(--scroller-padding);
}

.Headline_text {
	white-space: nowrap;
	font-family: var(--gothic-font);
	padding: 0;
	font-size: var(--headline-size);
	letter-spacing: -2px;
	line-height: 100%;
	visibility: visible;
	opacity: 1.0;
	-webkit-transition: opacity var(--transition);
	transition: opacity var(--transition);
  position: relative;
}

.Headline_hintWrapper {
  position: absolute;
}

.Headline_hint {
  -webkit-animation: Headline_hint--popIn 8.4s ease-in-out 1 forwards, var(--headline-hint-dot-animation);
          animation: Headline_hint--popIn 8.4s ease-in-out 1 forwards, var(--headline-hint-dot-animation);
  visibility: visible;
  opacity: 1;
  display: inline-block;
}

.Headline_hint--1 {
  -webkit-animation-delay: 0s, 1.4s;
          animation-delay: 0s, 1.4s;
}

.Headline_hint--2 {
  -webkit-animation-delay: 0s, 1.525s;
          animation-delay: 0s, 1.525s;
}

.Headline_hint--3 {
  -webkit-animation-delay: 0s, 1.65s;
          animation-delay: 0s, 1.65s;
}

/* Overrididing styles */
.Headline_text--hidden {
	visibility: hidden;
	opacity: 0;
}

/* Keyframes */
@-webkit-keyframes textScroller {
	0% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(-100%);
		        transform: translateX(-100%);
	}
}
@keyframes textScroller {
	0% {
		-webkit-transform: translateX(0%);
		        transform: translateX(0%);
	}
	100% {
		-webkit-transform: translateX(-100%);
		        transform: translateX(-100%);
	}
}

@-webkit-keyframes Headline_hint--popIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  95% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Headline_hint--popIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  95% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes Headline_hint--dot {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  12.5% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
  }
  25% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes Headline_hint--dot {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  12.5% {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
  }
  25% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

/* Media rules */

@media screen and (max-width: 768px) {
  .Headline_large {
    font-size: calc(0.85 * var(--headline-size));
  }
  
  .Headline_small {
    font-size: calc(0.6 * var(--headline-size));
  }

  .Headline_scrollWrapper {
    height: calc(0.6 * var(--headline-size));
  }
}

@media screen and (max-width: 480px) {
  .Headline_text {
    letter-spacing: -1px;
  }
  .Headline_large {
    font-size: calc(0.65 * var(--headline-size));
  }

  .Headline_small {
    font-size: calc(0.4 * var(--headline-size));
  }

  .Headline_scrollWrapper {
    height: calc(0.48 * var(--headline-size));
  }
}