.TagCloud {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 3rem;
	width: 70%;
	height: calc(var(--home-image-height) - 5px);
	padding: 0 3vw;
}

.TagCloud_headers {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	gap: 2.5vw;
	height: 100%;
}

.TagCloud_header {
	font-family: var(--sans-serif-font);
	font-weight: 300;
	font-size: 3rem;
	position: relative;
	cursor: pointer;
	letter-spacing: -0.125rem;
	line-height: 100%;
}

.TagCloud_header::after {
	content: "";
	position: absolute;
	z-index: -1;
	left: 0;
	right: 100%;
	bottom: -0.05em;
	background: var(--light);
	height: 0.075em;
	transition: right 0.4s ease-in-out;
}

.TagCloud_header--red::after {
	background: var(--red);
}

.TagCloud_header--blue::after {
	background: var(--blue);
}

.TagCloud_header--yellow::after {
	background: var(--yellow);
}

.TagCloud_header:hover::after,
.TagCloud_header:focus::after,
.TagCloud_header:active::after,
.TagCloud_header--active::after {
	right: 0;
}

.TagCloud_wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}

.TagCloud_block {
	position: absolute;
	visibility: visible;
	font-size: 2rem; /* This is a fallback for if JS script fails */
	opacity: 1.0;
	transition: opacity var(--transition);
	text-align: center;
}

.TagCloud_cloud {
	position: absolute;
	visibility: visible;
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: flex-start;
	flex-wrap: wrap;
	column-gap: 1.5vw;
	opacity: 1.0;
	transition: opacity var(--transition);
}

.TagCloud_block--hidden,
.TagCloud_cloud--hidden {
	visibility: hidden;
	pointer-events: none;
	opacity: 0;
}

.TagCloud_tag {
	font-size: 2rem;
	font-weight: 300;
}

.TagCloud_tag--red {
	color: var(--red);
}

.TagCloud_tag--blue {
	color: var(--blue);
}

.TagCloud_tag--yellow {
	color: var(--yellow);
}

@media screen and (max-width: 1024px) {
  .TagCloud_header {
    font-size: 2.5rem;
  }

  .TagCloud_block {
    font-size: 1.6rem; /* Fallback; overriden by JS*/
  }

  .TagCloud_tag {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 768px) {
  .TagCloud {
    width: 100%;
    padding: 0;
    height: unset;
  }

  .TagCloud_headers {
    align-items: flex-start;
  }

  .TagCloud_block {
    font-size: 1.4rem; /* Fallback; overriden by JS */
  }

}

@media screen and (max-width: 480px) {
  .TagCloud {
    flex-direction: column;
    gap: 1rem;
  }

  .TagCloud_headers {
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .TagCloud_header {
    font-size: calc(max(9vw, 1rem));
  }

  .TagCloud_wrapper {
    height: 6rem;
  }

  .TagCloud_tag {
    font-size: calc(max(6vw, 1rem));
  }
}