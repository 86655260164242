.ContactBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 30%;
	gap: 1.5rem;
}

.ContactBox_image {
	height: var(--home-image-height);
	aspect-ratio: 1/1;
	object-fit: cover;
	object-position: 50% 0%;
	border-radius: 25px;
}

@media screen and (max-width: 768px) {
  
  .ContactBox {
    width: 100%;
  }

}